import React from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const LandingContent = () => {
  const theme = useTheme();
  return (
    <Box sx={{ textAlign: "center", gap: "24px" }}>
      <Typography
        variant="h2"
        sx={{
          marginBottom: "28px",
          fontWeight: "bold",
          color: theme.colors.landingTitle,
        }}
      >
        How can I help you today?
      </Typography>
      <Box sx={{ width: "60%", margin: "0 auto", textAlign: "left" }}>
        <Typography
          variant="body1"
          sx={{
            color: theme.colors.primaryText,
          }}
        >
          Hi, I’m Enzo, your AI worker. I specialize in logistics, moving, and
          related topics, and I'm continuously learning to assist you better.
        </Typography>
      </Box>
    </Box>
  );
};

export default LandingContent;
