import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import chatHistories from "./chatHistories.json";
import { backendURL, modelInfo } from "./RAGModels";
import Sidebar from "./components/Sidebar";
import MainChatSection from "./components/MainChatSection";
import HeaderBar from "./components/HeaderBar";
import LandingPage from "./components/LandingPage";

const App = () => {
  const [chats, setChats] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [showLandingPage, setShowLandingPage] = useState(true);
  const [sourceUrlInfo, setSourceUrlInfo] = useState({
    exclusionFilters: [],
    inclusionFilters: [],
    seedUrlList: [],
  });

  const baseUrl = backendURL;
  const selectedModel = modelInfo;

  useEffect(() => {
    initializeChats();
  }, []);

  useEffect(() => {
    fetchSourceConfig();
  }, [baseUrl]);

  const initializeChats = () => {
    const updatedChats = chatHistories.map((chat) => ({
      ...chat,
      sessionId: undefined,
    }));
    setChats(updatedChats);
  };

  const fetchSourceConfig = () => {
    if (!baseUrl) return;
    fetch(baseUrl + "urls", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSourceUrlInfo({
          exclusionFilters: data.exclusionFilters ?? [],
          inclusionFilters: data.inclusionFilters ?? [],
          seedUrlList: data.seedUrlList ?? [],
        });
      })
      .catch((err) => console.error("Error fetching URLs:", err));
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const handleSendQuestion = () => {
    if (!question.trim()) return;

    if (showLandingPage) {
      createNewChatWithQuestion();
    } else {
      if (!selectedChatId) return;
      sendQuestionToBackend(selectedChatId, chats);
    }
  };

  const createNewChatWithQuestion = () => {
    const newChatId = `chat${chats.length + 1}`;
    const newChat = {
      id: newChatId,
      title: `New Chat ${chats.length + 1}`,
      history: [],
      sessionId: undefined,
    };

    const updatedChats = [newChat, ...chats];
    setChats(updatedChats);
    setSelectedChatId(newChatId);
    setShowLandingPage(false);

    sendQuestionToBackend(newChatId, updatedChats);
  };

  const sendQuestionToBackend = (chatId, chatArray) => {
    setIsLoading(true);
    const selectedChat = chatArray.find((c) => c.id === chatId);
    if (!selectedChat) {
      console.error("Selected chat not found!");
      setIsLoading(false);
      return;
    }

    fetch(baseUrl + "docs", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        requestSessionId: selectedChat.sessionId,
        question: question,
        modelId: selectedModel?.modelId,
      }),
    })
      .then((res) => res.json())
      .then((data) => updateChatHistory(chatId, data))
      .catch((err) => handleFetchError(chatId, err));
  };

  const updateChatHistory = (chatId, data) => {
    setIsLoading(false);
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === chatId
          ? {
              ...chat,
              sessionId: data.sessionId,
              history: [
                ...chat.history,
                {
                  question: question,
                  response: data.response,
                  citation: data.citation,
                },
              ],
            }
          : chat
      )
    );
    setQuestion("");
  };

  const handleFetchError = (chatId, err) => {
    console.error("Error:", err);
    setIsLoading(false);
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === chatId
          ? {
              ...chat,
              history: [
                ...chat.history,
                {
                  question: question,
                  response:
                    "Error generating an answer. Please contact Shipeezi Support.",
                  citation: undefined,
                },
              ],
            }
          : chat
      )
    );
    setQuestion("");
  };

  const onClearHistory = () => {
    if (!selectedChatId) return;
    setChats((prevChats) =>
      prevChats.map((chat) =>
        chat.id === selectedChatId
          ? { ...chat, history: [], sessionId: undefined }
          : chat
      )
    );
  };

  const handleSelectChat = (chatId) => {
    setShowLandingPage(false);
    setSelectedChatId(chatId);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <HeaderBar
        chats={chats}
        setChats={setChats}
        selectedChatId={selectedChatId}
        setSelectedChatId={setSelectedChatId}
        toggleSidebar={toggleSidebar}
        isSidebarVisible={isSidebarVisible}
      />
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        {isSidebarVisible && (
          <Sidebar
            chats={chats}
            setChats={setChats}
            selectedChatId={selectedChatId}
            setSelectedChatId={handleSelectChat}
            isSidebarVisible={isSidebarVisible}
          />
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {showLandingPage ? (
            <LandingPage
              question={question}
              setQuestion={setQuestion}
              handleSendQuestion={handleSendQuestion}
              isLoading={isLoading}
              showLandingPage={showLandingPage}
            />
          ) : (
            <MainChatSection
              chats={chats}
              selectedChatId={selectedChatId}
              question={question}
              setQuestion={setQuestion}
              handleSendQuestion={handleSendQuestion}
              isLoading={isLoading}
              showLandingPage={showLandingPage}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default App;
