import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  colors: {
    // landing page
    landingTitle: "#1E293B",

    // header bar
    headerGradient: "linear-gradient(90deg, #F0F7FF 0%, #FFF1F2 100%)",
    titleText: "#1E293B",
    versionText: "#0C5554",
    versionBackground: "#C6FFF7",

    // side bar
    pageBackground: "#F8FAFC",
    sidebarFooterBackground: "#fafafa",
    sidebarBorder: "#F1F5F9",

    // main chat section
    primaryText: "#475569",
    infoText: "#334155",
    linkText: "#0055A4",
    inputBackground: "#FFFFFF",
    inputBorder: "#94A3B8",
    inputShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
    sendButtonBackground: "#0A8AED",
    sendButtonText: "#F0F7FF",

    // chat
    questionBackground: "#DFEEFF",
    chatShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
    responseBackground: "#F1F5F9",
    buttonHoverBackground: "#F1F5F9",
  },
  borderRadius: "16px",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
