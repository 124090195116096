import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Box, Typography, IconButton, Snackbar, Alert } from "@mui/material";
import { SpeakerHigh, Copy, ThumbsUp, ThumbsDown } from "@phosphor-icons/react";
import { useTheme } from "@mui/material/styles";
import LandingContent from "./components/LandingContent";

const Chat = ({ history }) => {
  const boxRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [history]);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => setSnackbarOpen(true))
      .catch((err) => console.error("Failed to copy:", err));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        ref={boxRef}
        sx={{
          padding: "16px",
          overflowY: "auto",
          height: "100%",
          display: "flex",
          fontSize: "16px",
          flexDirection: "column",
          gap: "12px",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        {history?.length > 0 ? (
          history.map((entry, index) => (
            <React.Fragment key={index}>
              <Box
                sx={{
                  alignSelf: "flex-end",
                  maxWidth: "65%",
                  backgroundColor: theme.colors.questionBackground,
                  color: theme.colors.primaryText,
                  borderRadius: "16px",
                  padding: "10px 16px",
                  wordWrap: "break-word",
                  lineHeight: "24px",
                }}
              >
                <Typography sx={{ fontSize: "16px" }}>
                  {entry.question}
                </Typography>
              </Box>

              <Box
                sx={{
                  alignSelf: "flex-start",
                  width: "93%",
                  backgroundColor: theme.colors.responseBackground,
                  color: theme.colors.primaryText,
                  borderRadius: "16px",
                  padding: "28px",
                  wordWrap: "break-word",
                  lineHeight: "24px",
                }}
              >
                <ReactMarkdown
                  children={entry.response}
                  remarkPlugins={[remarkGfm]}
                  components={{
                    a: ({ href, children }) => (
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: theme.colors.linkText,
                          textDecoration: "underline",
                        }}
                      >
                        {children}
                      </a>
                    ),
                  }}
                />
                {/* {entry.citation && (
                  <Typography
                    variant="caption"
                    sx={{ color: "#6b7280", fontSize: "12px" }}
                  >
                    {entry.citation}
                  </Typography>
                )} */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <IconButton
                  aria-label="Speaker high"
                  disabled="true"
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.colors.buttonHoverBackground,
                    },
                  }}
                >
                  <SpeakerHigh size={16} />
                </IconButton>
                <IconButton
                  aria-label="Copy response"
                  onClick={() => handleCopy(entry.response)}
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.colors.buttonHoverBackground,
                    },
                  }}
                >
                  <Copy size={16} />
                </IconButton>
                <IconButton
                  aria-label="Thumbs up"
                  disabled="true"
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.colors.buttonHoverBackground,
                    },
                  }}
                >
                  <ThumbsUp size={16} />
                </IconButton>
                <IconButton
                  aria-label="Thumbs down"
                  disabled="true"
                  sx={{
                    "&:hover": {
                      backgroundColor: theme.colors.buttonHoverBackground,
                    },
                  }}
                >
                  <ThumbsDown size={16} />
                </IconButton>
              </Box>
            </React.Fragment>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <LandingContent />
          </Box>
        )}
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Text copied to clipboard!
        </Alert>
      </Snackbar>
    </>
  );
};

Chat.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      response: PropTypes.string.isRequired,
      citation: PropTypes.string,
    })
  ).isRequired,
};

Chat.defaultProps = {
  history: [],
};

export default Chat;
