import React, { useRef, useEffect } from "react";
import { Box } from "@mui/material";
import Chat from "../Chat";
import { useTheme } from "@mui/material/styles";
import InputSection from "./InputSection";
import LandingPage from "./LandingPage";

const MainChatSection = ({
  chats,
  selectedChatId,
  question,
  setQuestion,
  handleSendQuestion,
  isLoading,
  showLandingPage,
}) => {
  const theme = useTheme();
  const selectedChat = chats.find((chat) => chat.id === selectedChatId);
  const chatDisplayRef = useRef(null);

  useEffect(() => {
    if (chatDisplayRef.current) {
      chatDisplayRef.current.scrollTop = chatDisplayRef.current.scrollHeight;
    }
  }, [selectedChat?.history]);

  const noChatSelected = !selectedChatId;
  const emptyChatHistory = selectedChat && selectedChat.history?.length === 0;

  if (noChatSelected || emptyChatHistory) {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          backgroundColor: theme.colors.pageBackground,
          padding: "72px 0px 24px 0px",
        }}
      >
        <LandingPage
          question={question}
          setQuestion={setQuestion}
          handleSendQuestion={handleSendQuestion}
          isLoading={isLoading}
          showLandingPage={true}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "stretch",
        backgroundColor: theme.colors.pageBackground,
        overflow: "hidden",
        padding: "72px 0px 24px 0px",
      }}
    >
      <Box
        sx={{
          width: "70%",
          maxWidth: "70%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          ref={chatDisplayRef}
          sx={{
            flexGrow: 1,
            padding: "16px",
            borderRadius: "16px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          }}
        >
          <Chat history={selectedChat.history} />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <InputSection
            question={question}
            setQuestion={setQuestion}
            handleSendQuestion={handleSendQuestion}
            isLoading={isLoading}
            showLandingPage={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MainChatSection;
