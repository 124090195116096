import React, { useState } from "react";
import { Box, IconButton, Typography, Button } from "@mui/material";
import {
  AppWindow,
  NotePencil,
  Share,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import { useTheme } from "@mui/material/styles";

const HeaderBar = ({
  chats,
  setChats,
  selectedChatId,
  setSelectedChatId,
  toggleSidebar,
  isSidebarVisible,
}) => {
  const theme = useTheme();

  const addNewChat = () => {
    const newChatId = `chat${chats.length + 1}`;
    const newChat = {
      id: newChatId,
      title: `New Chat ${chats.length + 1}`,
      history: [],
      sessionId: undefined,
    };
    setChats([newChat, ...chats]);
    setSelectedChatId(newChatId);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 20px 16px 0px",
        background: theme.colors.headerGradient,
        borderBottom: "1px solid #ddd",
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "8px 16px",
        }}
      >
        <IconButton onClick={toggleSidebar} sx={{ padding: "16px" }}>
          <AppWindow size={24} />
        </IconButton>
        {isSidebarVisible && (
          <IconButton sx={{ padding: "16px" }} disabled="true">
            <MagnifyingGlass size={24} />
          </IconButton>
        )}
        <IconButton onClick={addNewChat}>
          <NotePencil size={24} />
        </IconButton>
        <Box sx={{ padding: "16px" }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: theme.colors.titleText }}
          >
            ENZO
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "4px 12px",
            borderRadius: "16px",
            backgroundColor: theme.colors.versionBackground,
            color: theme.colors.versionText,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="caption">0.1 pre-alpha</Typography>
        </Box>
      </Box>

      <Box sx={{ paddingRight: "16px" }}>
        <Button
          variant="outlined"
          disabled="true"
          startIcon={<Share size={20} />}
          sx={{
            textTransform: "none",
            padding: "4px 12px",
            borderRadius: "25px",
            color: "#475569",
            borderColor: "#475569",
            "&:hover": {
              borderColor: "#475569",
              backgroundColor: "rgba(71, 85, 105, 0.1)",
            },
          }}
        >
          Share
        </Button>
      </Box>
    </Box>
  );
};

export default HeaderBar;
