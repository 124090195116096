import React from "react";
import {
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import { ArrowUp } from "@phosphor-icons/react";
import { useTheme } from "@mui/material/styles";

const InputSection = ({
  question,
  setQuestion,
  handleSendQuestion,
  isLoading,
  showLandingPage,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "92%",
      }}
    >
      {isLoading && (
        <Box
          sx={{
            width: showLandingPage ? "46%" : "100%",
            backgroundColor: theme.colors.responseBackground,
            borderRadius: "16px",
            boxShadow: theme.colors.inputShadow,
            padding: "8px 16px",
            marginBottom: "16px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <CircularProgress
            size={24}
            sx={{
              color: theme.colors.sendButtonBackground,
            }}
          />
        </Box>
      )}

      <Box
        sx={{
          width: showLandingPage ? "46%" : "100%",
          backgroundColor: theme.colors.responseBackground,
          borderRadius: "16px",
          boxShadow: theme.colors.inputShadow,
          padding: "20px",
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Message Enzo..."
          value={question}
          multiline
          minRows={showLandingPage ? 3 : 1}
          maxRows={3}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isLoading) {
              e.preventDefault();
              handleSendQuestion();
            }
          }}
          sx={{
            backgroundColor: theme.colors.inputBackground,
            borderRadius: "8px",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "8px",
              },
            },
            "& .MuiInputBase-input": {
              color: theme.colors.primaryText,
            },
          }}
          disabled={isLoading}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: "12px",
            marginTop: "8px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={handleSendQuestion}
              sx={{
                color: theme.colors.sendButtonText,
                backgroundColor: theme.colors.sendButtonBackground,
                opacity: isLoading ? 0.5 : 1,
                pointerEvents: isLoading ? "none" : "auto",
              }}
            >
              <ArrowUp size={24} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: theme.colors.infoText, fontSize: "12px" }}
            >
              ENZO can make mistakes.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: theme.colors.linkText,
                fontSize: "12px",
                paddingLeft: "2px",
              }}
            >
              Check important info.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InputSection;
