import React from "react";
import {
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Typography,
} from "@mui/material";
import { SignOut } from "@phosphor-icons/react";
import { useTheme } from "@mui/material/styles";

const Sidebar = ({
  chats,
  setChats,
  selectedChatId,
  setSelectedChatId,
  isSidebarVisible,
}) => {
  const theme = useTheme();
  if (!isSidebarVisible) return null;

  const addNewChat = () => {
    const newChatId = `chat${chats.length + 1}`;
    const newChat = {
      id: newChatId,
      title: `New Chat ${chats.length + 1}`,
      history: [],
      sessionId: undefined,
    };
    setChats([newChat, ...chats]);
    setSelectedChatId(newChatId);
  };

  return (
    <Paper
      sx={{
        width: "18%",
        minWidth: "100px",
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 72px)",
        marginTop: "72px",
        backgroundColor: theme.colors.pageBackground,
        borderRight: `1px solid ${theme.colors.sidebarBorder}`,
        flexShrink: 0,
      }}
    >
      <List sx={{ flexGrow: 1, overflowY: "auto" }}>
        {chats.slice().map((chat) => (
          <ListItem
            button
            key={chat.id}
            selected={chat.id === selectedChatId}
            onClick={() => setSelectedChatId(chat.id)}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <ListItemText primary={chat.title} />
          </ListItem>
        ))}
      </List>

      <Box
        sx={{
          padding: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: theme.colors.sidebarFooterBackground,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }}
        >
          <Avatar
            sx={{
              width: 40,
              height: 40,
            }}
          />
          <Box
            sx={{
              textAlign: "center",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Welcome!
            </Typography>
            <Typography variant="subtitle2" fontWeight="bold">
              Carlos Ferri
            </Typography>
          </Box>
        </Box>

        <IconButton
          sx={{
            [theme.breakpoints.down("md")]: {
              order: -1,
            },
            [theme.breakpoints.up("sm")]: {
              alignSelf: "center",
            },
          }}
        >
          <SignOut size={24} />
        </IconButton>
      </Box>
    </Paper>
  );
};

export default Sidebar;
