import React from "react";
import { Box } from "@mui/material";
import LandingContent from "./LandingContent";
import InputSection from "./InputSection";

const LandingPage = ({
  question,
  setQuestion,
  handleSendQuestion,
  isLoading,
  showLandingPage,
}) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: "16px",
        gap: "28px",
      }}
    >
      <LandingContent />
      <InputSection
        question={question}
        setQuestion={setQuestion}
        handleSendQuestion={handleSendQuestion}
        isLoading={isLoading}
        showLandingPage={showLandingPage}
      />
    </Box>
  );
};

export default LandingPage;
