export const backendURL =
  "https://ygaimv4x8d.execute-api.us-east-1.amazonaws.com/prod/";

export const modelInfo = {
  customizationsSupported: [],
  guardrailsSupported: true,
  inferenceTypesSupported: ["ON_DEMAND"],
  inputModalities: ["TEXT"],
  modelArn:
    "arn:aws:bedrock:us-east-1::foundation-model/anthropic.claude-3-5-sonnet-20240620-v1:0",
  modelId: "anthropic.claude-3-5-sonnet-20240620-v1:0",
  modelLifecycle: {
    status: "ACTIVE",
  },
  modelName: "Claude 3.5 Sonnet",
  outputModalities: ["TEXT"],
  providerName: "Anthropic",
  responseStreamingSupported: true,
};
